.landingSec3 {
  // padding: 8rem 0;
  position: relative;

  @include respond(tab-land) {
    padding: 4rem 0;
    background-color: #2e8de1;
  }

  &__content {
    display: flex;
    padding: 8rem 0;
    position: relative;

    @include respond(tab-port) {
      flex-direction: column;

      padding: 4rem 0;
    }

    &::before {
      content: "";

      width: 100%;
      height: 100%;

      background: url("../../../../assets/img/Landing/blueBG.png") no-repeat;

      background-position: center;
      background-size: contain;

      position: absolute;
      top: 0;
      left: -14rem;

      z-index: -1;

      @include respond(big-desktop) {
        background-position: left;
      }

      @include respond(tab-land) {
        background: none;
      }
    }
  }

  &__reverse {
    display: flex;
    flex-direction: row-reverse;
    padding: 8rem 0 14rem 0;

    position: relative;
    z-index: 2;

    @include respond(tab-port) {
      flex-direction: column;
      padding: 4rem 0;
    }

    &::before {
      content: "";

      width: 100%;
      height: 100%;

      background: url("../../../../assets/img/Landing/blueBG2.png") no-repeat;

      background-position: center;
      background-size: contain;

      position: absolute;
      top: 0;
      right: -18rem;

      z-index: -1;

      @include respond(big-desktop) {
        background-position: right;
      }

      @include respond(tab-land) {
        background: none;
      }
    }

    .landingSec3__left {
      flex: 1;
      color: $color-white;
      padding: 0 2rem 0 8rem;

      @include respond(tab-port) {
        padding: 0 1rem;
      }
    }

    .landingSec3__right-arrowbody {
      width: 4rem;
      height: 3.5rem;
      background-color: $color-white;
      border-radius: 0.5rem;
      box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
        rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;

      position: absolute;
      bottom: -11rem;
      left: 20rem;

      display: flex;
      justify-content: center;
      align-items: center;

      &--left {
        left: 15rem;
      }
    }
  }

  &__left {
    flex: 1;
    color: $color-white;
    padding: 0 2rem;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__left-title {
    width: 90%;
    font-size: 3rem;
    font-weight: bold;
  }

  &__right {
    flex: 1.2;

    display: flex;
    align-items: center;
  }

  &__right-content {
    position: relative;
  }

  &__right-img {
    max-width: 100%;
  }

  &__right-img2 {
    width: 50%;

    position: absolute;
    right: -3.2rem;
    bottom: -1.5rem;
  }

  &__right-img3 {
    max-width: 30rem;
    width: 100%;

    position: absolute;
    left: -4rem;
    bottom: -10rem;
  }

  &__right-img4 {
    max-width: 30rem;
    width: 100%;

    position: absolute;
    right: 0;
    bottom: -5rem;
  }

  &__right-arrowbody {
    width: 4rem;
    height: 3.5rem;
    background-color: $color-white;
    border-radius: 0.5rem;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
      rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;

    position: absolute;
    bottom: -4rem;
    left: 40rem;

    display: flex;
    justify-content: center;
    align-items: center;

    @include respond(phone) {
      left: 20rem;
    }

    &--left {
      left: 35rem;

      @include respond(phone) {
        left: 15rem;
      }
    }

    &--2 {
    }
  }

  &__right-arrow {
    color: $color-blue;
    font-size: 2rem;
  }
}
