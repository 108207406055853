.usersThink {
  padding: 10rem 0;
  position: relative;

  @include respond(tab-land) {
    background: linear-gradient(
      90deg,
      rgb(96, 156, 216) 22%,
      rgb(64, 148, 228) 47%,
      rgb(51, 136, 214) 78%
    );
  }

  &::before {
    content: "";

    background: url("../../../../assets/img/Landing/gridImg.png") no-repeat;

    width: 30%;
    height: 60%;

    position: absolute;
    bottom: 7.5rem;
    left: 1rem;

    // transform: scale(0.6);
  }

  &::after {
    content: "";

    width: 100%;
    height: 100%;

    // background: url("../../../../assets/img/Landing/bluelinearBG.png") no-repeat;
    background: url("../../../../assets/img/Landing/blueBG.png") no-repeat;

    background-position: center;
    background-size: contain;

    position: absolute;
    top: 0;
    left: -18rem;

    z-index: -1;

    @include respond(tab-land) {
      background: none;
    }
  }

  &__content {
    position: relative;

    &::after {
      content: url("../../../../assets/img/Landing/quotes-sign.png");

      position: absolute;
      top: 0;
      right: 20rem;

      transform: scale(0.5);

      @include respond(tab-port) {
        transform: scale(0.3);
      }

      @include respond(phone) {
        content: none;
      }
    }
  }

  &__title {
    color: $color-white;
    font-size: 3rem;
    font-family: $font-primary-bold;
  }

  &__card {
    max-width: 32rem !important;
    width: 100%;

    padding: 2.5rem;

    background-color: $color-white;
    border-radius: 1.2rem;
    margin-right: 3rem;

    box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;

    @include respond(phone) {
    }
  }

  &__card-company {
    // width: 10rem;
    height: 5rem;
  }

  &__card-text {
    font-size: 1.22rem;
    padding: 3rem 0 5rem 0;
    text-decoration: none;
    color: black;
    line-height: 2rem;
  }

  &__card-bottom {
    display: flex;
    align-items: center;

    border-top: 1px solid $color-gray-3;
    padding-top: 1.2rem;
  }

  &__card-img {
    width: 5rem;
    height: 5rem;
  }

  &__card-personal {
    margin-left: 1rem;
  }

  &__card-person {
    font-size: 1.4rem;
    font-weight: bold;
  }

  &__card-job {
    margin: 0.6rem 0;
    font-size: 1.1rem;
  }

  &__star {
    font-size: 1.2rem;
    color: $color-orange;
  }

  .slick-slider {
    margin-top: 6rem;
    // margin-bottom: -10rem;
    padding-bottom: 1rem;

    overflow: hidden;

    @include respond(tab-land) {
      // margin-top: 8rem;
      margin-bottom: 0;
    }
  }

  .slick-track {
    display: flex;
  }

  .slick-slide {
    width: 100% !important;
    display: flex;
    justify-content: center;
  }

  .slick-arrow {
    position: absolute;
    top: 4.5rem;
    right: 5rem;

    width: 4rem;
    height: 4rem;

    box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px,
      rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
    background-color: $color-white;

    display: flex !important;
    justify-content: center;
    align-items: center;

    cursor: pointer;
  }

  .slick-prev {
    right: 10rem;
  }

  .slide__icon {
    font-size: 2rem;
    color: $color-blue;
  }
}
