.accordionSection {
  &__title {
    font-size: 1.8rem;
    font-family: $font-primary-bold;
  }

  &__accs {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 4rem;

    margin-top: 4rem;

    @include respond(tab-port) {
      grid-template-columns: 1fr;
    }
  }

  .accordion {
    &__item {
      margin-top: 2rem;
      border: none;
    }

    &__icon {
      font-size: 3rem;
      color: $color-blue;

      &--2 {
      }
    }

    &__button,
    &__panel {
      border: 1px solid $color-gray-3;
      border-radius: 0.5rem;
      background-color: $color-white;
    }

    &__button {
      height: 6rem;
      font-size: 1.4rem;
      font-weight: bold;
    }

    &__panel {
      font-size: 1.3rem;

      padding: 1.8rem 3rem 1.8rem 3.5rem !important;
    }
  }
}
