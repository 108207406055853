.landingSec1 {
  padding: 10rem 0;
  margin-top: 7rem;

  position: relative;

  &::after {
    content: "";

    width: 55%;
    height: 100%;

    background: url("../../../../assets/img/Landing/blueBG2.png") no-repeat;

    background-position: center;
    background-size: contain;

    position: absolute;
    top: 0;
    right: -18rem;

    z-index: -1;

    @include respond(big-desktop) {
      background-position: right;
    }

    @include respond(tab-land) {
      background: none;
    }
  }

  &__content {
    display: flex;

    @include respond(tab-port) {
      flex-direction: column;
      justify-content: center;
    }
  }

  &__left {
    flex: 1;
    margin-right: 15rem;

    display: flex;
    flex-direction: column;
    justify-content: center;

    @include respond(tab-land) {
      margin-right: 7rem;
    }

    @include respond(tab-port) {
      margin-right: 0;
      text-align: center;
    }
  }

  // &__title {
  //   font-size: $font-size-extra-largest;
  //   font-family: $font-primary-bold;
  // }

  .landingSec1__title {
    font-size: 4rem;
    font-family: $font-primary-bold;
  }

  &__text {
    font-size: 1.8rem;
    margin: 1rem 0;
  }

  &__buttons {
    display: flex;

    @include respond(tab-port) {
      justify-content: center;
    }
  }

  &__btn {
    display: flex;
    align-items: center;

    &--1 {
      margin-right: 2rem;
    }

    &--2 {
      font-weight: 100 !important;
    }
  }

  &__icon1-left {
    margin-left: 2rem;
    font-size: $font-size-large;
  }

  &__icon2-left {
    margin-right: 0.5rem;
    font-size: $font-size-large;
  }

  &__right {
    flex: 1.5;

    @include respond(tab-land) {
      flex: 1.2;
    }

    @include respond(tab-port) {
      margin-top: 2rem;
    }
  }

  &__right-content {
    width: 70%;
    position: relative;
    z-index: 2;

    @include respond(tab-port) {
      margin: 0 auto;
    }

    @include respond(phone) {
      width: 100%;
    }
  }

  &__right-img {
    width: 100%;
  }

  &__right-img2 {
    position: absolute;

    &--1 {
      width: 30%;
      right: 0;
      bottom: -6rem;
    }

    &--2 {
      width: 40%;
      right: -8rem;
      bottom: 50%;
    }

    &--3 {
      width: 40%;
      left: 5rem;
      bottom: -2rem;
    }
  }
}
