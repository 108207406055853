.accordion {
  border: none !important;

  &__item {
    position: relative;
    border-top: none !important;
  }

  &__icon {
    position: absolute;

    &--1 {
      left: 1.2rem;
    }

    &--2 {
      right: 1.2rem;
    }
  }

  &__button[aria-expanded='true'],
  &__button[aria-selected='true'] {
    .accordion__icon--2 {
      transform: rotate(180deg) !important;
    }
  }

  &__button {
    padding: 0 3.5rem 0 3.5rem !important;

    display: flex;
    align-items: center;

    &::before {
      content: none !important;
    }
  }

  &__panel {
    padding: 1rem 3rem 1rem 3.5rem !important;
  }
}
