.pricingTestimional {
  padding: 12rem 0 4rem 0;

  &__content {
    width: 90%;
    margin: 0 auto;

    overflow: hidden;

    @include respond(phone) {
      width: 100%;
    }
  }

  &__body {
    width: 90%;
    margin: 0 auto;
    padding: 4rem 0;

    position: relative;

    @include respond(tab-land) {
      width: 100%;
    }

    &::before {
      content: '';

      width: 100%;
      height: 100%;

      background: url('../../../../assets/img/Pricing/blueBg.png') no-repeat;
      background-size: contain;
      background-position: left;

      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;

      @include respond(phone) {
        background-size: cover;
      }
    }
  }

  &__slide {
    display: flex !important;
    align-items: center;

    width: 80% !important;
    margin: 0 auto;

    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-radius: 2.2rem;
    background-color: $color-white;

    @include respond(tab-land) {
      width: 85% !important;
    }

    @include respond(phone) {
      flex-direction: column;
      width: 75%;
      border-top-left-radius: 4rem;
      border-top-right-radius: 4rem;
    }
  }

  &__left {
    flex: 1;

    @include respond(phone) {
      width: 100%;
    }
  }

  &__img {
    width: 100%;
    display: block;
  }

  &__right {
    flex: 1;

    padding: 2rem 5rem;

    @include respond(tab-land) {
      padding: 2rem;
    }
  }

  &__text {
    font-size: 1.4rem;
    width: 92%;
  }

  &__name {
    font-size: 1.8rem;
    font-weight: bold;
    padding: 3rem 0 1rem 0;
  }

  &__job {
    font-size: 1.1rem;
    color: $color-gray-1;
  }

  .slick-track {
    display: flex;
  }

  .slick-slide {
    width: 90% !important;
    display: flex;
    justify-content: center;
  }

  .slick-slider {
    position: relative;
  }

  .slick-arrow {
    position: absolute;
    top: 45%;

    width: 4rem;
    height: 4rem;

    box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px,
      rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
    background-color: $color-white;

    display: flex !important;
    justify-content: center;
    align-items: center;

    cursor: pointer;
    z-index: 2;
  }

  .slick-prev {
    left: 7%;
  }

  .slick-next {
    right: 7%;
  }

  .slide__icon {
    font-size: 2rem;
    color: $color-blue;
  }
}
