.landingRow {
  padding: 8rem 0 8rem;

  &__content {
    display: flex;

    @include respond(tab-port) {
      flex-direction: column;
    }
  }

  &__reverse {
    flex-direction: row-reverse;

    @include respond(tab-port) {
      flex-direction: column;
    }

    .landingRow__right-content {
      width: 90%;
      margin: 0 auto 0 0;

      @include respond(tab-port) {
        width: 80%;
        margin: 0 auto;
      }

      @include respond(phone) {
        width: 100%;
      }
    }
  }

  &__left {
    flex: 1;
  }

  &__img {
    width: 100%;
  }

  &__right {
    flex: 1;

    display: flex;
    align-items: center;

    @include respond(tab-port) {
      margin-top: 3rem;
    }
  }

  &__right-content {
    width: 80%;
    margin: 0 auto;

    @include respond(phone) {
      width: 100%;
    }
  }

  &__title {
    font-size: 2.8rem;
    font-family: $font-primary-bold;

    @include respond(tab-port) {
      text-align: center;
    }
  }

  &__data {
    margin-top: 4rem;
  }

  &__single {
    display: flex;
    align-items: center;

    margin-top: 2rem;
  }

  &__icon-body {
    margin-right: 3rem;

    width: 5rem;
    height: 5rem;

    display: flex;
    align-items: center;
    justify-content: center;

    background-color: $color-white;
    border-radius: 50%;
    position: relative;

    &::after {
      content: "";

      width: 100%;
      height: 100%;

      background-color: $color-gray-3;
      border-radius: 50%;

      position: absolute;
      top: 4px;
      left: 4px;

      z-index: -1;
    }
  }

  &__icon {
    transform: scale(0.5);
    width: 5rem;
    height: 4rem;
  }

  &__text {
    font-size: 1.2rem;
  }
}
