li {
  list-style-type: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

button {
  cursor: pointer;
}

button:focus,
:focus {
  outline: none;
}

a {
  text-decoration: none;
  &:hover,
  &:focus,
  &:active {
    outline: 0;
    box-shadow: none;
    text-decoration: none;
  }
}
