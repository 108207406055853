.pricingSales {
  margin-top: $nav-height;

  &__content {
    text-align: center;
  }

  &__top-title {
    color: $color-black !important;
  }

  &__cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  &__card {
    width: 25rem;
    height: 43rem;
    margin-right: 1rem;
    margin-top: 2rem;

    padding: 4rem 3rem;

    border-radius: 1rem;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 5px;

    position: relative;
  }

  &__card-popularimg {
    width: 2.5rem;

    position: absolute;
    top: 0;
    right: 2rem;
  }

  &__card-popular {
    background-color: #4252f1;
    color: $color-white;

    width: 100%;
    font-size: 1.1rem;
    font-weight: bold;
    text-transform: uppercase;
    padding: 0.5px 0;

    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;

    position: absolute;
    left: 0;
    bottom: 0;
  }

  &__card-badge {
    font-size: 1.4rem;
    font-weight: bold;
  }

  &__card-text {
    font-size: 1rem;
    padding: 1rem 0;
  }

  &__card-price {
    font-size: 2.8rem;
    font-family: $font-primary-bold;
  }

  &__currency {
    font-size: 2rem;
    display: inline-flex;
    transform: translate(-0.1rem, -0.8rem);
  }

  &__card-month {
    padding: 1rem 0;
    font-size: 1.1rem;
  }

  &__btn {
    width: 100%;
  }

  &__hr {
    width: 100%;
    height: 1px;
    background-color: $color-gray-2;
    margin: 1.5rem 0;
  }

  &__card-badgetext {
    text-align: left;
    font-size: 1.1rem;

    span {
      font-weight: bold;
      text-transform: uppercase;
    }
  }

  &__card-list {
    width: 85%;
    display: flex;
    align-items: center;
    margin: 0 auto;
    margin-top: 2rem;
    text-align: left;
  }

  &__card-icon {
    width: 2rem;
    height: 2rem;
    margin-right: 1.5rem;
  }

  &__card-info {
    font-size: 1.1rem;
  }
}
