.webapps {
  padding: 10rem 0;

  &__top {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include respond(tab-port) {
      flex-direction: column;
    }
  }

  &__title {
    font-size: 2.4rem;
    font-weight: bold;
  }

  &__btn {
    display: flex;
    align-items: center;
    color: $color-blue-3;

    border: 1px solid $color-blue;
    border-radius: 10rem;
    padding: 0.5rem 2rem;
    font-size: 1rem;
    font-weight: lighter;

    @include respond(tab-port) {
      margin-top: 2rem;
    }
  }

  &__arrow {
    font-size: 1.5rem;
    margin-left: 2rem;
  }

  &__row {
    display: flex;
    align-items: center;

    margin-top: 3rem;

    @include respond(tab-port) {
      margin-top: 0;

      flex-direction: column;
    }
  }

  &__namebox {
    max-width: 32rem;
    width: 100%;
    height: 9rem;
    background-color: $color-white;
    box-shadow: rgba(99, 99, 99, 0.05) 0px 2px 8px 0px;

    display: flex;
    align-items: center;
    padding-left: 4rem;

    position: relative;

    @include respond(tab-port) {
      margin-top: 2rem;
    }

    &::after {
      content: '';

      width: 3px;
      height: 100%;

      background-color: $color-blue;
      border-radius: 10px;

      position: absolute;
      top: 0;
      left: 0;
    }
  }

  &__name {
    font-size: 2rem;
    font-weight: bold;
    margin-right: 1rem;
  }

  &__apps {
    display: flex;
    align-items: center;
    padding: 0 3rem 0 4rem;

    @include respond(tab-port) {
      margin-top: 2rem;
    }
  }

  &__app {
    width: 14rem;
    height: 6.5rem;
    padding: 1rem;

    border: 1px solid rgba(213, 212, 212, 0.3);
    border-radius: 3px;

    display: flex;
    justify-content: center;
    align-items: center;

    @include respond(tab-land) {
      width: 10rem;
    }

    @include respond(phone) {
      width: 6rem;
    }

    &:not(:last-child) {
      margin-right: 1.5rem;
    }
  }

  &__img {
    max-width: 100%;
  }

  &__img2 {
    width: 5rem;
  }

  &__icon-body {
    width: 3rem;
    height: 3rem;

    box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px,
      rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__icon {
    font-size: 1.6rem;
    color: $color-blue-3;
  }
}
