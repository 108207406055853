.mobileNav {
  max-width: 30rem;
  width: 100%;
  height: 100vh;
  background-color: $color-white;
  padding-bottom: 1rem;
  overflow-y: auto;

  transform: translateX(-100%);
  transition: 0.3s ease-out;

  position: fixed;
  top: 0;
  left: 0;
  z-index: 9000;

  &__logo {
    width: 11rem;
    height: auto;
  }

  &__close {
    width: 100%;
    height: $nav-height;

    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__closebox {
    width: 3rem;
    height: 3rem;
    margin-right: 2rem;
    cursor: pointer;

    position: relative;

    &::after {
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      background-color: transparent;
      border: 1px solid $color-black;
      border-radius: 50%;

      position: absolute;
      top: 0;
      left: 0;
    }
  }

  &__closeicn {
    width: 100%;
    height: 100%;
    transform: rotate(90deg);
    cursor: pointer;
  }

  &__list {
    width: 100%;
  }

  &__activeLink {
    border-left: 3px solid rgb(226, 152, 247);
    border-right: 3px solid rgb(226, 152, 247);
  }

  &__link {
    width: 100%;
    height: 100%;
    font-size: $font-size-large;
    background-color: $color-white;
    border-top: 1px solid $color-gray-3;

    overflow: hidden;

    display: flex;
    flex-direction: column;
    align-items: center;

    position: relative;

    &:hover .mobileNav__al {
      background-color: rgb(242, 223, 247);
      transition: 0.3s ease-in-out;
    }
  }

  &__al {
    width: 100%;
    height: 100%;
    position: relative;
    padding-left: 1rem;
    color: $color-black;

    display: flex;
    align-items: center;
  }

  &__linkName {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 1.5rem 0;
  }

  &__icon {
    width: 68%;
    height: 5.5rem;
    background-color: transparent;

    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    position: absolute;
    top: 0;
    right: 0;
    z-index: 9999;

    &:hover {
      transition: 0.3s ease-in-out;
    }
  }

  &__arrow {
    font-size: $font-size-larger;
    width: 2rem;
    height: 2rem;

    display: block;
    margin-left: auto;
    margin-right: 2.5rem;

    transition: 0.3s ease-in-out;
  }

  //dropdown links

  &__dropdown {
    width: 100%;
    height: 0;
    transition: 0.3s ease-out;

    li {
      width: 100%;
      border-top: 1px solid $color-gray-3;

      cursor: pointer;

      a {
        display: block;
        padding: 1rem 0;
        padding-left: 2rem;

        &:active,
        &:visited,
        &:link {
          color: $color-black;
        }

        &:hover {
          background-color: rgb(226, 224, 226);
          transition: 0.3s ease-in-out;
        }
      }
    }
  }

  &__show {
    // height: 100%;
    // height: 26rem;
    transition: height 0.3s ease-in;
  }

  &__dropLink {
    font-size: $font-size-medium;
  }
}

.fixedBack {
  display: none;
  @include respond(tab-land) {
    width: 100%;
    height: 100%;

    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 8000;
  }
}

// dropdownmobile
.hamburger {
  width: 2rem;
  height: 2rem;

  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  overflow: hidden;

  @include respond(tab-land) {
    display: flex;
  }

  &__line {
    width: 100%;
    height: 2px;
    background-color: $color-black;
    transition: all 0.4s ease-out;

    &--two {
      margin: 3px 0;

      animation: 0.5s addLine forwards;
    }
  }
}

.arrowShow {
  transform: rotate(180deg);
}

.navShow {
  @include respond(tab-land) {
    transform: translateX(0);
    z-index: 9999;
    visibility: visible;
  }
}

// scrollbar design
.mobileNav::-webkit-scrollbar {
  width: 0.5em;
}

.mobileNav::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.mobileNav::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}

@keyframes removeLine {
  0% {
    transform: translateX(0);
  }

  30% {
    transform: translateX(50%);
  }

  100% {
    transform: translateX(110%);
    display: none;
  }
}

@keyframes addLine {
  0% {
    transform: translateX(110%);
    display: none;
  }

  30% {
    transform: translateX(50%);
  }

  100% {
    transform: translateX(0%);
  }
}

.mobileNav__linkName {
  color: $color-black;
}
