@font-face {
  font-family: 'Segoe UI Regular';
  font-style: normal;
  font-weight: normal;
  src: local('Segoe UI Regular'),
    url('../../assets/font/Segoe\ UI.woff') format('woff');
}

@font-face {
  font-family: 'Segoe UI Bold';
  font-style: normal;
  font-weight: normal;
  src: local('Segoe UI Bold'),
    url('../../assets/font/Segoe\ UI\ Bold.woff') format('woff');
}

.button-unstyled {
  background-color: $color-white;
  font-weight: 600;
  border: none;
  background-color: transparent;
  font-size: $font-size-small;
}

.button-primary {
  border: none;
  background: linear-gradient(
    90deg,
    rgb(96, 156, 216) 22%,
    rgb(64, 148, 228) 47%,
    rgb(51, 136, 214) 78%
  );
  padding: 1rem 2.5rem;
  border-radius: 10rem;
  color: $color-white;
  font-size: $font-size-small;
}

.button-primary-hover {
  border: none;
  background: linear-gradient(
    90deg,
    rgb(96, 156, 216) 22%,
    rgb(64, 148, 228) 47%,
    rgb(51, 136, 214) 78%
  );
  padding: 1rem 2.5rem;
  border-radius: 10rem;
  color: $color-white;
  font-size: $font-size-small;
  transition: 0.4s ease-in-out;

  &:hover {
    animation: pulse-white 0.8s ease-in-out;
  }
}

@keyframes pulse-white {
  0% {
    box-shadow: 0 0 0 0 rgba(128, 173, 217, 0.4);
  }

  70% {
    box-shadow: 0 0 0 1.2rem rgba(128, 173, 217, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(128, 173, 217, 0);
  }
}
