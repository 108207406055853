.footer {
  background-color: $color-black;
  color: $color-blue-3;
  padding: 3rem 0 1rem 0;

  &__top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 3rem 0;
    border-bottom: 1px solid rgba(239, 239, 239, 0.2);

    @include respond(phone) {
      flex-direction: column;
      text-align: center;
    }
  }

  &__top-btn {
    display: flex;
    align-items: center;
  }

  &__btn-arrow {
    font-size: 1.8rem;
    margin-left: 1.6rem;
  }

  &__title {
    color: $color-white;

    @include respond(phone) {
      margin-bottom: 2rem;
    }
  }

  &__top-title {
    font-size: 2.2rem;
    font-family: $font-primary-bold;
  }

  &__logo {
    font-size: $font-size-larger;
    display: flex;
    align-items: center;

    @include respond(phone) {
      justify-content: center;
    }
  }

  &__logoicon {
    width: 4rem;
    height: 100%;
    fill: $color-white;
    margin-right: 1rem;
  }

  &__text {
    margin-top: 1rem;
    font-size: $font-size-normal;
  }

  &__social {
    display: flex;

    font-size: $font-size-largest;

    li {
      margin-right: 1.5rem;

      a {
        color: $color-white;
      }
    }
  }

  &__links {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  &__middle {
    margin: 2rem 0 3rem 0;

    .link {
      font-size: $font-size-normal;
      font-weight: bold;
      margin-top: 2rem;
      margin-right: 3rem;

      @include respond(phone) {
        margin-top: 3rem;
      }

      &__title {
        color: $color-gray-1;
        margin-bottom: 3rem;
      }

      &__list {
        li {
          margin-bottom: 1.8rem;

          a {
            font-size: 1.3rem;
            color: $color-white;
            font-weight: normal;
          }
        }
      }
    }
  }

  &__buttons {
    flex-direction: column;
    justify-content: space-evenly;
  }

  &__btn {
    border: 1px solid $color-gray-2;
    background-color: transparent;
    margin: 0;

    &:first-child {
      margin: 1rem 0;

      @include respond(phone) {
        margin: 3rem 0 1rem 0;
      }
    }

    @include respond(phone) {
    }
  }

  &__language {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 2rem;

    position: relative;
  }

  &__arrow {
    position: absolute;
    right: 0;
    display: flex;
    font-size: $font-size-normal;
  }

  &__flag {
    width: 2rem;
    height: 100%;
    margin-right: 0.5rem;
  }

  &__select {
    border: none;
    color: $color-blue-3;
    background-color: transparent;
    appearance: none;
    padding: 0.5rem 1.4rem 0.5rem 0.5rem;

    position: relative;
    z-index: 2;

    option {
      color: $color-black;
    }
  }

  &__bottom {
    border-top: 1px solid rgba(239, 239, 239, 0.2);
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    font-size: $font-size-small;
    padding: 0 0 2rem 0;
    color: $color-white;

    @include respond(phone) {
      justify-content: center;
    }

    .info {
      display: flex;
      margin-top: 2rem;

      &__text {
        display: flex;
        align-items: center;

        &--right {
          margin-right: 3rem;
        }
      }

      &__privacy {
        display: flex;
        margin-top: 2rem;

        @include respond(tab-port) {
          margin: 2rem 2rem 0 2rem;
        }

        &--line {
          padding: 0 1rem;
        }

        &--term {
          display: flex;
        }

        a {
          color: $color-white;
        }
      }
    }
  }

  &__icon {
    margin-right: 0.5rem;
    font-size: 1.5rem;
  }
}
