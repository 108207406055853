@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100&family=Oswald:wght@500&family=Poppins:ital,wght@0,400;1,700&display=swap");

// colors
$color-white: #fff;
$color-black: #000;
$color-blue: #4480ff;
$color-blue-2: #2e8de1;
$color-blue-3: #5b74f3;
$color-gray-1: #b1b1b1;
$color-gray-2: #fcfcfc;
$color-gray-3: #efefef;
$color-gray-5: #d5d4d4;

$color-orange: #f9bf4a;

//font-size
$font-size-extra-small: 1rem;
$font-size-small: 1.2rem;
$font-size-normal: 1.4rem;
$font-size-medium: 1.6rem;
$font-size-large: 1.8rem;
$font-size-larger: 2.4rem;
$font-size-largest: 2.8rem;
$font-size-extra-largest: 3rem;
$font-size-extra-larger: 3.6rem;
$font-size-extra-large: 4.2rem;

//font-family
$font-primary-regular: "Oswald Light 300", sans-serif;
$font-primary-bold: "Oswald";

// nav
$nav-height: 8rem;
