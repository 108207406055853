.nav {
  width: 100%;
  height: $nav-height;
  display: flex;
  justify-content: center;
  align-items: center;

  position: fixed;
  top: 0;
  left: 0;
  z-index: 500;

  transition: 0.3s ease-in-out;

  &__sticky {
    background-color: $color-white;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  }

  &__isWhite {
    background-color: $color-white;
  }

  &__content {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__anchor {
    height: $nav-height;
    display: flex;
    align-items: center;
  }

  &__activeLink {
    position: relative;

    &::after {
      content: "";
      width: 100%;
      height: 2px;
      display: inline-block;
      background-color: blue !important;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 3;
    }
  }

  .nav__logo {
    width: 11rem;
    height: auto;
  }

  &__btn {
    &:not(:last-child) {
      margin-right: 1.5rem;
    }
  }

  &__logo-link {
    color: $color-black;
  }

  &__logo-content {
    display: flex;
    align-items: center;
  }

  &__logo {
    width: 3rem;
    height: 3rem;
    margin-right: 0.5rem;
  }

  &__logo-title {
    font-size: $font-size-medium;
    font-weight: bold;
    letter-spacing: 1px;
  }

  &__menu {
    display: flex;
    // position: relative;

    @include respond(tab-land) {
      display: none;
    }
  }

  &__list {
    display: flex;
    align-items: center;
    font-size: $font-size-normal;
    font-weight: 600;
    height: 4.5rem;
    color: $color-black;

    &:not(:last-child) {
      margin-right: 3rem;
    }

    a {
      color: $color-black;
      letter-spacing: 0.5px;

      &:active,
      &:focus {
        color: $color-black;
      }
    }

    &--d-arrow {
      vertical-align: middle;
      margin-top: 0.4rem;

      transition: 0.3s ease-in-out;
    }
  }

  .dropdown {
    width: 100%;
    position: absolute;
    top: 6.2rem;
    left: 51%;
    transform: translate(-50%, 0);
    padding: 1.5rem 3rem 2.5rem 3rem;
    background-color: $color-white;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 5px 0px;
    border-radius: 1rem;
    cursor: default;
    display: none;

    &--products {
      max-width: 88rem;

      &::before {
        content: "";
        width: 0;
        height: 0;
        border-left: 0.8rem solid transparent;
        border-right: 0.8rem solid transparent;
        border-bottom: 1.5rem solid rgba(46, 141, 225, 0.05);

        position: absolute;
        top: -1.5rem;
        left: 25%;
        z-index: 2;
      }
    }

    &--solutions {
      max-width: 70rem;

      &::before {
        content: "";
        width: 0;
        height: 0;
        border-left: 0.8rem solid transparent;
        border-right: 0.8rem solid transparent;
        border-bottom: 1.5rem solid rgba(46, 141, 225, 0.05);

        position: absolute;
        top: -1.6rem;
        left: 43%;
        z-index: 2;
      }
    }

    &--resources {
      max-width: 70rem;

      &::before {
        content: "";
        width: 0;
        height: 0;
        border-left: 0.8rem solid transparent;
        border-right: 0.8rem solid transparent;
        border-bottom: 1.5rem solid rgba(46, 141, 225, 0.05);

        position: absolute;
        top: -1.6rem;
        right: 40%;
        z-index: 2;
      }
    }

    &__name {
      color: $color-gray-5;
      font-weight: normal;
      font-size: 1.1rem;
      text-transform: uppercase;
    }

    &__data {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 0.5rem;

      margin-top: 1rem;
    }

    &__link {
      width: 100%;
      height: 6rem;
      display: flex;
      align-items: center;
      padding: 0.5rem 1rem 0.5rem 0.5rem;
      cursor: default;

      &:hover {
        box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 4px;
        transition: 0.2s ease-in-out;
      }

      &:hover .dropdown__icn {
        background-color: rgba(46, 141, 225, 0.2);
        transition: 0.4s ease-in-out;
      }
    }

    &__icn {
      width: 4rem;
      height: 4rem;
      display: flex;
      border-radius: 50%;
      position: relative;

      &::after {
        content: "";
        border-radius: 50%;
        width: 100%;
        height: 100%;
        display: block;
        background-color: transparent;

        position: absolute;
        top: 0;
      }
    }

    &__icon {
      // padding: 1rem 1.2rem;

      width: 4.5rem;
      height: 100%;
      transform: scale(0.4);
    }

    &__info {
      margin-left: 0.5rem;
    }

    &__title {
      font-size: $font-size-small;
    }

    &__text {
      font-size: $font-size-extra-small;
      font-weight: normal;
      margin-top: 0.5rem;
      letter-spacing: normal;
    }
  }

  .dropdown-flex {
    padding: 0;

    &__content {
      display: flex;
    }

    &__data {
      flex: 1;

      display: flex;
      padding: 2rem 3rem 2.5rem 3rem;

      &:not(:last-child) {
        border-right: 1px solid $color-gray-3;
      }
    }

    &__column {
      font-size: 1.1rem;

      flex: 1;

      &:not(:last-child) {
        margin-right: 4rem;
      }
    }

    &__title {
      margin-bottom: 3rem;
      color: $color-gray-5;
    }

    &__links {
      // display: flex;
    }

    &__link {
      display: block;

      &:not(:last-child) {
        margin-bottom: 1.5rem;
      }

      &:hover {
        color: $color-blue;
      }
    }
  }

  .nav__list:hover .dropdown {
    display: block;
  }

  .nav__list:hover .nav__list--d-arrow {
    transform: rotate(-180deg);
    transition: 0.3s ease-in-out;
  }

  .nav__list {
    font-family: "Oswald";
  }
}
